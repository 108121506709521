/* -------------------------- fonts */

/* Default */
@font-face {
  font-family: 'Satoshi';
  src: url('../public/fonts/Satoshi/Satoshi-Variable.woff2') format('woff2'),
    url('../public/fonts/Satoshi/Satoshi-Variable.woff') format('woff'),
    url('../public/fonts/Satoshi/Satoshi-Variable.ttf') format('truetype');
  font-weight: 300 900;
  font-display: swap;
  font-style: normal;
}

/* Italic */
@font-face {
  font-family: 'Satoshi-Italic';
  src: url('../public/fonts/Satoshi/Satoshi-VariableItalic.woff2') format('woff2'),
    url('../public/fonts/Satoshi/Satoshi-VariableItalic.woff') format('woff'),
    url('../public/fonts/Satoshi/Satoshi-VariableItalic.ttf') format('truetype');
  font-weight: 300 900;
  font-display: swap;
  font-style: italic;
}

/* Alt */
@font-face {
  font-family: JetBrainsMono-Regular;
  src: url("../public/fonts/JetBrainsMono/JetBrainsMono-Regular.woff2");
}

/* -------------------------- variables */

:root {
  --colorGradient: linear-gradient(-45deg,
      #9D753A 10%,
      #F0915C 25%,
      #CB2950 65%,
      #F65C81 100%);

  --colorWhiteGradient: linear-gradient(
    -45deg,
    rgba(255, 255, 255, 0.8) 1.3%,
    rgb(255, 255, 255) 83.33%,
    rgba(255, 255, 255, 0) 100%
  );

  --cardBackground: rgba(35, 37, 35, 0.50);

  --altChipBGGray: #2E312E
}

/* -------------------------- pre-styles */

.textGradient {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  background: var(--colorGradient);
  background-clip: text;
  color: transparent;
}

.textWhiteGradient {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  background: var(--colorWhiteGradient);
  background-clip: text;
  color: transparent;
}

body {
  margin: 0;
  font-family: Satoshi, sans-serif;
  color: rgb(241, 241, 241);
  background: #010101;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  font-size: 15.355px;
  color: white;
  cursor: pointer;
  font-family: Satoshi, sans-serif;
}

a {
  cursor: pointer;
  text-decoration: none;
  font-size: 15.355px;
  color: white;
}

h1 {
  margin: 0;

  font-size: 64px;
  font-weight: 900;
  line-height: 86px;
}

h2 {
  margin: 0;

  font-size: 48px;
  font-weight: 700;
  line-height: 65px;
}

h3 {
  margin: 0;

  font-size: 24px;
  font-weight: 700;
  line-height: 34.2px;

  text-transform: capitalize;
}

h4 {
  margin: 0;

  font-size: 20px;
  font-weight: 700;
  line-height: 27px;
  color: #B4B3B4;
}

h5 {
  margin: 0;

  font-size: 18.31px;
  font-weight: 700;
  line-height: 25px;
}

h6 {
  margin: 0;

  font-size: 15.83px;
  font-weight: 700;
  line-height: 21px;
}

p {
  margin: 0;
  font-size: 20px;
  font-weight: 400;
  line-height: 28.5px;
}

figure {
  margin: 0;
}

ol, ul {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-left: 30px;
  font-size: 20px;
  line-height: 28.5px;
  margin: 0;
}

@media (max-width: 768px) {
  h1 {
    font-size: 28px;
    line-height: 38px;
  }

  h2 {
    font-size: 24px;
    line-height: 32px;
  }

  h4 {
    font-size: 16px;
    line-height: 24.72px;
  }

  h5 {
    font-size: 16px;
    line-height: 22px;
  }

  h6 {
    font-size: 15px;
  }
}