#loadingAnimationContainer {
  width: 100vw;
  height: 100vh;
  display: grid;
  place-items: center;
  background-color: black;
}

#loadingScreenPlayer {
  height: 46px; 
  width: 55px;
}