.baseContentContainer {
  display: flex;
  width: 100%;
  max-width: 1095px;
  flex-direction: column;
  align-items: flex-start;
}

.cardContent {
  width: calc(100% - (2 * 30px));
  max-width: calc(1095px - (2 * 30px));
  flex-direction: row;

  padding: 32px 30px;
  justify-content: space-between;

  border-radius: 10px;
  background: rgba(35, 37, 35, 0.50);
}

.imageContent {
  flex-direction: column;
  overflow: hidden;
  border-radius: 10px;
  border: 1px solid #131313;
}

.imageContent > img {
  width: 100%;
}

.spanInitialWidth {
  width: initial;
}

.gridDisplay {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  align-items: stretch;
}

.autoRows {
  grid-auto-rows: 1fr;
}

@media (max-width: 768px) {

  .baseContentContainer {
    max-width: 500px;
  }

  .cardContent{
    flex-direction: column;
    gap: 25px;
  }

  .gridDisplay {
    grid-template-columns: 1fr;
  }
}