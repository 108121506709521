.container {
  display: flex;
  width: calc(100vw - (40px * 2));
  padding: 50px 40px 50px 40px;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
}

.padded {
  padding: 104px 40px 50px 40px;
}

@media (max-width: 768px) {
  .container {
    width: calc(100vw - (40px * 2));
    padding: 40px 40px 40px 40px;
    gap: 40px;
  }

  .padded {
    padding: 70px 40px 40px 40px;
  }
}