.label {
  font-size: 15.827px;
  color: #FFFFFFB2;
  font-weight: 700;
  line-height: 21px;
  text-transform: uppercase;
}

@media (max-width: 768px) {
  .label {
    font-size: 13px;
    line-height: 18px;
  }
}