.footerContainer {
  display: flex;
  width: calc(100vw - (2*48px));
  padding: 12.5px 48px;
  justify-content: space-between;
  align-items: center;

  background: linear-gradient(to bottom right, rgba(19, 20, 19, 0.86) 0%, rgba(19, 20, 19, 0.47) 50%) bottom right / 50% 50% no-repeat, linear-gradient(to bottom left, rgba(19, 20, 19, 0.86) 0%, rgba(19, 20, 19, 0.47) 50%) bottom left / 50% 50% no-repeat, linear-gradient(to top left, rgba(19, 20, 19, 0.86) 0%, rgba(19, 20, 19, 0.47) 50%) top left / 50% 50% no-repeat, linear-gradient(to top right, rgba(19, 20, 19, 0.86) 0%, rgba(19, 20, 19, 0.47) 50%) top right / 50% 50% no-repeat;
  box-shadow: 0px -11px 4px 0px rgba(0, 0, 0, 0.25);
}

.footerLeftSection {
  display: flex;
  padding: 6.75px 9.75px;
  align-items: center;
  gap: 22.5px;

  border-radius: 100px;
  background: rgba(50, 50, 50, 0.20);
}

.footerRightSection {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 26px;
}

.footerText {
  color: rgba(100, 100, 100, 0.90);
  text-align: right;
  font-family: JetBrainsMono-Regular, monospace;
  font-size: 14px;
  font-weight: 400;
  line-height: 24.5px;
}

.footerRightDivider {
  background-color: rgba(255, 255, 255, 0.28);
  width: 1px;
  height: 39.753px;
  flex-shrink: 0;
}

.footerSocialsContainer {
  display: flex;
  padding: 0px 5.106px;
  align-items: center;
  gap: 19.148px;
}


/* ============================ footer chips ================= */

.footerChip {
  display: flex;
  padding: 6.75px 9.75px;
  align-items: center;
  gap: 22.5px;

  border-radius: 100px;
  background: rgba(50, 50, 50, 0.20);
}

.footerLogo {
  height: '32px';
}

.breadcrumb {
  display: flex;
  padding: 6.75px 9.75px;
  justify-content: center;
  align-items: center;
  gap: 22.5px;
  border-radius: 16px;
  background: rgba(50, 50, 50, 0.20);

  border: 0;
  color: rgba(175, 175, 175, 0.90);
  font-size: 11.25px;
  font-weight: 500;
  line-height: 15px;

  cursor: pointer;
}

.footerSocialsContainer img {
  max-height: 16.93px;
}

@media (max-width: 768px) {
  .footerContainer {
    flex-direction: column;
    align-items: flex-start;
    padding: 48px 48px;
    gap: 24px;
  }

  .footerRightSection {
    flex-direction: column;
    align-items: flex-start;
  }

  .footerText {
    text-align: left;
  }

  .footerRightDivider {
    height: 1px;
    width: 39.753px;
  }
}