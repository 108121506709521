.player {
  height: 100%;
  width: 100%;
}

.lottie {
  height: 46px;
  width: 55px;
  padding: 0;
  padding-top: 2px;
  overflow: hidden;
  border-radius: 31px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
}
