.navContainer {
  display: flex;
  width: calc(100vw - (2*48px));
  padding: 35px 48px;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(to bottom, black, transparent);

  position: fixed;
  z-index: 100;
}

.leftSection, .centerSection, .rightSection {
  display: flex;
  align-items: center;
  align-self: stretch;
}

.leftSection, .rightSection {
  gap: 8.6px;
  flex: 1 0 0;
}

.rightSection {
  justify-content: flex-end;
}

.centerSection {
  padding: 7px 20.17px;
  justify-content: center;
  gap: 12px;

  border-radius: 84.041px;
  background:#171719b9;
  backdrop-filter: blur(5px);

  color: #cfcfcf;
  opacity: 1;
}


@media (max-width: 768px) {
  .navContainer {
    width: calc(100vw - (2*17px));
    padding: 17px 17px;
  }

  .centerSection {
    position: absolute;
    top: 80px;
    right: 24px;
    flex-direction: column;


    border-radius: 15px;
    padding: 10px 20px;
  }

  .navContainer> .centerSection > .selected {
    background: #cfcfcf;
  }

  .navContainer> .centerSection > .selected:hover{
    background: #8D8D8D;
  }
}