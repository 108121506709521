.outerNavChip {
  height: 46.2px;
}

.animatedNavChipIconContainer {
  display: flex;
  position: relative;
  align-items: center;
  height: 34px;
}

.logo>img {
  padding-top: 0px;
  padding-bottom: 1px;
}

/* ============================ centerChips ========================== */

.default {
  box-sizing: border-box;

  /* layout */
  display: flex;
  padding: 4.607px 12.284px;
  align-items: flex-start;
  gap: 7.678px;

  /* container styling */
  border-radius: 15.355px;
  border: 1px solid transparent;

  /* typography styling */
  font-size: 15.355px;
  font-weight: 500;
  line-height: 21px;

  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: rgba(255, 255, 255, 1);
}



