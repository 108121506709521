.endContainer {
  padding-bottom: 154px!important;
}

.smallGap {
  gap: 28.5px;
}

.mediumGap {
  gap: 50px;
}

.largeGap {
  gap: 70px;
}

@media (max-width: 768px) {
  .endContainer {
    padding-bottom: 144px!important;
  }
}