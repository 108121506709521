.projectCardContainer {
  display: flex;
  container-type: inline-size;
  container-name: projectCardContainer;
  max-width: 438px;
  width: 100%;
  min-height: 236.29px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;

  border-radius: 8px;
  border: 1px solid transparent;
  background:var(--cardBackground);
  backdrop-filter: blur(21px);
  box-shadow: 0px 0px 8px 8px rgba(0, 0, 0, 0.25);
  cursor: pointer;
}

.cardContentBlock {
  display: flex;
  max-width: 216px;
  padding: 30px 29px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex-shrink: 0;
  align-self: stretch;
}

.cardContentBlock > img {
  max-height: 27.75px;
}

.cardHeadingBlock {
  max-width: 158px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

.cardH6 {
  color: #FFFFFFCC
}

.cardDateLabel {
  color: rgba(255, 255, 255, 0.80);
  font-size: 9.318px;
  font-weight: 400;
  line-height: 13px;
}

.cardContentDivider {
  width: 36.031px;
  height: 1px;
  flex-shrink: 0;

  background-color: #FFF;

  margin-top: 4.39px;
}

.cardThumbnailBlock {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 24px;
  flex: 1 0 0;
  align-self: stretch;
}

.cardThumbnailBlock > img {
  border-bottom-right-radius: 10px;
  border-top-left-radius: 10px;
  height: 144.25px;
}

.projectCardHoverChip {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 0;
  position: absolute;
  top: 22px;
  right: 0;
  padding: 8px 14px 8px 16px;
  justify-content: flex-end;

  transition: none;
  
  opacity: 0;
}

@container projectCardContainer (max-width: 345px) {
  .projectCardContainer {
    flex-direction: column-reverse;
    padding-top:15px;
  }

  .projectCardHoverChip{
    display: none;
  }

  .cardThumbnailBlock > img {
    position: absolute;
    top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 9px;
    border-bottom-left-radius: 9px;
  }
}

@media (max-width: 768px) {
  .projectCardContainer {
    border: 1px solid rgba(255,255,255,0.2)
  }
}
