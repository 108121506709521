/* ------------------------- Base chip */
.chip {
  box-sizing: border-box;

  /* layout */
  display: flex;
  justify-content: center;
  padding: 5px 14px;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;

  /* container styling */
  border-radius: 31px;
  background: #171719;
  border: 1px solid;

  /* typography styling */
  font-size: 15.355px;
  font-weight: 500;
  line-height: 21px;
}

.altBG {
  background-color: var(--altChipBGGray);
}

/* ------------------------- IconText chip */

.iconPositionRight {
  padding: 5px 14px 5px 20px;
}

.iconPositionLeft {
  padding: 5px 20px 5px 14px;
}