.oneFieldForm {
  box-sizing: border-box;
  display: flex;
  width: 289px;
  align-items: center;

  border-radius: 11.077px;
  border: 5px solid #3E3E3E;
  background: #2E312E;

}

.inputField {
  color: white;
  display: flex;
  padding-left: 15px;
  flex: 1 0 0;
  align-self: stretch;
  outline: 0;

  font-family: Satoshi;
  text-align: left;
  font-size: 13.633px;
  font-weight: 500;
  line-height: 18px;
  background-color: transparent;
  border: 1px rgba(13, 13, 14, 0) solid;
}

.inputField ::placeholder {
  color: #D5D6D5;
  font-size: 13.633px;
  font-weight: 500;
  line-height: 18px;
}

.inputField:hover {
  background-color: rgba(13, 13, 14, 0.2) ;
  transition: all ease 300ms;
}

.inputField:focus {
  border: 1px rgba(13, 13, 14, 0.80) solid;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;

  transition: all 300ms;
}

.inputSubmit {
  border: 0;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;

  display: flex;
  height: 46.012px;
  width: 53.68px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  background: rgba(13, 13, 14, 0.80);
  transition: all 300ms ease;
}

.inputSubmit:hover {
  background: rgba(13, 13, 14, 1);
}