#hero {
  width: initial;
  height: calc(100vh - (20px * 2));
  max-height: 1030px;
  padding: 30px 20px 30px 20px;
  justify-content: center;
  gap: 10px;
  
  background-color: #020202;
  background: url('../../assets/imgs/home/heroBG/heroBG-desktop.webp') no-repeat 50% 55%;
  overflow: hidden;
}

.heroContentContainer {
  align-items: center;
  gap: 12px;

  margin-top: 21vh;
}

.heroHeadingCTABlock {
  align-items: center;
  gap: 55px;
}

.heroHeadingBlock {
  align-items: center;
  gap: 20px;
}

.heroH1 {
  max-width: 891px;
  text-align: center;
}

.heroH4 {
  max-width: 589px;
  text-align: center;
}

.mainCTA {
  background: var(--colorGradient);
  background-size: 200%;
  animation: rotate 3000ms ease infinite forwards;

  align-items: flex-start;
  border-color: transparent;
  border: 0;
  border-radius: 28px;
  
  display: flex;
  justify-content: center;
  align-items: center;

  font-weight: 500;

  padding: 17px 18px 17px 18px !important;
  gap: 12.29px;
  position: relative;
  overflow: hidden;
  z-index: 1;

  transition: all ease 500ms;
}

@keyframes rotate {
	0% {
		background-position: 0% 100%;
	}
  20% {
		background-position: 0% 0%;
	}
	40% {
		background-position: 100% 0%;
	}
	60% {
		background-position: 100% 100%;
	}
  100% {
		background-position: 0% 100%;
	}
}

.mainCTA > svg {
  padding: 4px;
}

.mainCTA::before {
  content: '';
  top: 3px;
  right: 3.5px;
  left: 3.5px;
  bottom: 3px;

  background-color: #0C0C0C;

  border-radius: calc(28px - (2px));

  position: absolute;
  z-index: -1;
  transition: all ease 300ms;
}

.mainCTA:hover::before{
  
  top: 5.5px;
  right: 6px;
  left: 6px;
  bottom: 5.5px;

  border-radius: calc(28px - (4px));
}

.mainCTA::after {
  content: attr(data-content);

  color: #FFF;
  font-size: 15.356px;
  font-weight: 500;
  line-height: 21px;
}

.halfElipseContainer {
  display: flex;
  position: relative;
  height: 207px;

  top: 9vh;
}

@media (max-width: 768px) {
  #hero {
    background-image: url("../../assets/imgs/home/heroBG/heroBG-mobile.webp");
  }

  .heroContentContainer {
    margin-top: -10vh;
    gap: 28px;
  }

  .heroHeadingCTABlock {
    gap: 41px;
  }

  .heroHeadingBlock {
    gap: 17.234px;
  }

  .heroH1,.heroH4 {
    max-width: 340px;
  }

  .halfElipseContainer {
    height: 20px;
    top: 17vh;
  }

  .halfElipse {
    width: 1125px;
  }
}

/* --------------- Work section -------------------- */

#work {
  width: calc(100vw - (20px * 2));;
  padding: 200px 20px 130px 20px;
  justify-content: center;
  gap: 205px;
}

.workContentContainer {
  max-width: 900px;
}

@media (max-width: 768px) {
  #work {
    padding-top: 80px;
  }

  .workContentContainer {
    max-width: 438px;
  }
}


/* --------------- Newsletter section -------------------- */


#newsletter {
  container-type: inline-size;
  container-name: bannerContainer;
  padding: 0 40px;
  width: calc(100vw - (40px * 2));
  align-self: initial;
  justify-content: center;
  height: calc(100vh - (87px + 100px));
  background: url('../../assets/imgs/home/newsletterBG/newsletterBG.webp') 50% 0% no-repeat;
}

.newsletterBannerContainer {
  flex-direction: row;
  align-items: center;
  position: relative;
  top: -60px;

  justify-content: space-between;
  width: calc(100% - (66px * 2));
  padding: 51px 66px;

  border-radius: 10px 30px;
  background: linear-gradient(87deg, rgba(29, 29, 29, 0.80) 27.60%, rgba(35, 37, 35, 0.14) 100%), linear-gradient(317deg, #9D753A 0%, #F0915C 22.92%, #CB2950 58.85%, #F65C81 93.23%) center;
  box-shadow: 0px 0px 250px 200px rgba(246, 91, 129, 0.08);
}

.newsletterHeadingBlock {
  max-width: 379px;
  gap: 11px;
}

.newsletterH4 {
  font-weight: 500;
  color: #FFF;
}

.newsletterH6 {
  font-weight: 500;
  color: #B0B0B0;
}

#newsletterBannerTooltip {
  right: -45px;
  bottom: -30px;
}

.newsletterGraphicsBlock {
  display: flex;
  width: 100%;
  max-width: 410px;
  justify-content: space-between;
  align-items: flex-start;
  flex-shrink: 0;
}

.newsletterBannerVideo, .newsletterBannerImg {
  position: relative;
  border-radius: 5.084px;
  width: 84.251px;
  box-shadow: 2.9052045345306396px 2.178903579711914px 1.8157528638839722px 0px rgba(0, 0, 0, 0.25), 2.9052045345306396px 2.178903579711914px 1.8157528638839722px 0px rgba(0, 0, 0, 0.25);
}

.newsletterBannerVideo {
  top: -3.5px;
}

.newsletterBannerImg {
  top: 3.5px;
}

@container bannerContainer (max-width: 1040px) {
  .newsletterBannerContainer {
    max-width: 900px;
    gap: 70px;
  }
  
  .newsletterBannerImg {
    display: none;
  }

  .newsletterBannerVideo{
    top: 0px
  }

  .newsletterGraphicsBlock {
    max-width: 220px;
    align-items: center;
    justify-content: space-around;
  }
}

@media (max-width: 768px) {
  #newsletter {
    top: -2vh;
    height: 60vh;
    padding: 0px 20px;
    width: calc(100vw - (20px * 2));
  }

  .newsletterBannerContainer {
    margin-top: -80px;
    padding-left: 30px;
    padding-right: 30px;
    max-width: 438px;
    width: calc(100% - (30px * 2));
  }
  
  .newsletterGraphicsBlock {
    display: none;
  }

  .newsletterContentBlock > form {
    width: 100%;
  }

  .heroH6 {
    max-width: 191px;
  }
}

