.tooltipContainer {
  display: flex;
  position: absolute;
  bottom: 20px;
  right: 20px;
  align-content: flex-end;
  justify-content: flex-end;
  align-items: flex-end;
  z-index: 1;
}

.tooltipContentContainer{
  display: flex;
  position: relative;
  max-width: 300px;
  right: -36px;
  transform-origin: bottom right;
  padding: 15px;
  border-radius: 8px 8px 8px 8px;
  flex-wrap: wrap;
  background-color: #020203;
  z-index: 2;
}

.childAnimationContainer > span,
.childAnimationContainer > a {
  cursor: pointer;
  text-decoration: underline;
}

:has(> .tooltipContainer){
  position: relative;
}
